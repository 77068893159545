<!--
  Component to allow the upload of feedback data from Hotjar to link it to appointment data
-->
<template>
  <div class="container is-fluid" style="padding-top: 10px">
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
    <b-field>
      <b-input
        v-model="searchCriteria"
        :expanded="false"
        size="is-medium"
        type="search"
        icon="search"
        :placeholder="$t('Filter results')"
        @input="search"
      ></b-input>
    </b-field>
    <b-field horizontal position="is-left" :label="$t('Date Range')">
      <b-field expanded>
        <b-datepicker
          v-model="dateFrom"
          placeholder="Click to select..."
          icon="calendar"
          icon-pack="fad"
          :date-formatter="$d"
          :first-day-of-week="1"
        ></b-datepicker>
      </b-field>
      <b-field expanded>
        <b-datepicker
          v-model="dateTo"
          placeholder="Click to select..."
          icon="calendar"
          icon-pack="fad"
          :date-formatter="$d"
          :first-day-of-week="1"
        ></b-datepicker>
      </b-field>
      <div class="buttons">
        <b-button class="is-primary" @click="processData"
          >Apply Date Filter</b-button
        >
        <b-button class="is-primary" @click="resetDateFromAndTo"
          >Reset Date Filter</b-button
        >
      </div>
      <b-field style="padding-top: 5px">
        <b-switch v-model="autoRefresh">{{ $t('Auto refresh') }}</b-switch>
        <b-tooltip
          :label="$t('feedback-auto-refresh-help')"
          multilined
          position="is-left"
        >
          <b-icon
            icon="question-circle"
            size="is-small"
            type="is-primary"
          ></b-icon>
        </b-tooltip>
      </b-field>
    </b-field>
    <b-field horizontal position="is-left">
      <div class="buttons" style="padding-bottom: 1em">
        <b-button
          type="is-primary"
          icon-pack="fad"
          icon-left="download"
          @click="fetchAndProcessData()"
          >{{ $t('Refresh Data') }}</b-button
        >
        <b-button
          :disabled="feedback.length === 0"
          type="is-info"
          icon-left="download"
          icon-pack="fad"
          @click="downloadCsv()"
          >{{ $t('Export to CSV') }}</b-button
        >
      </div>
    </b-field>
    <div>
      <b-table
        :data="dateFilteredDataSet"
        :paginated="true"
        :per-page="5"
        style="overflow-x: scroll"
        striped
        detailed
        narrowed
        detail-key="feedbackDate"
        :show-detail-icon="true"
      >
        <template slot="empty">
          <p class="has-text-centered">{{ $t('No data to display') }}</p>
        </template>
        <template slot="detail" slot-scope="props">
          <div v-if="props.row.message" style="padding-bottom: 1rem">
            <p>
              <strong class="has-text-primary"
                >{{ $t('Customer Message') }}:</strong
              >
            </p>
            <p>{{ props.row.message }}</p>
          </div>
          <div v-if="props.row.parentResourceId" style="padding-bottom: 1rem">
            <p>
              <strong class="has-text-primary"
                >{{ $t('Parent Resource') }}:</strong
              >
            </p>
            <p>{{ props.row.parentResourceId }}</p>
          </div>
          <div v-if="props.row.workZones" style="padding-bottom: 1rem">
            <p>
              <strong class="has-text-primary"
                >{{ $t('Resource Work Zone Info') }}:</strong
              >
            </p>
            <span v-html="props.row.workZones"></span>
          </div>
          <div v-if="props.row.userAgent" style="padding-bottom: 1rem">
            <p>
              <strong class="has-text-primary">{{ $t('User Agent') }}:</strong>
            </p>
            <p>{{ props.row.userAgent }}</p>
          </div>
        </template>
        <b-table-column
          v-slot="props"
          field="feedbackDate"
          label="Date"
          sortable
          width="500"
        >
          {{ adjustToLocalTimezone(new Date(props.row['feedbackDate'])) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="appointmentNumber"
          label="Appointment"
          sortable
        >
          {{ props.row.appointmentNumber }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="resourceId"
          label="Engineer"
          sortable
        >
          {{ props.row.resourceId || '' }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="rating"
          label="Rating (1-5)"
          sortable
          numeric
          centered
        >
          <b-icon
            :icon="getIcon(props.row.rating)"
            :type="getIconColor(props.row.rating)"
            pack="fad"
          ></b-icon>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="message"
          label="Message"
          centered
          width="500"
          sortable
        >
          <b-icon
            :icon="getMsgIcon(props.row.message)"
            :type="getMsgIconColor(props.row.message)"
          ></b-icon>
        </b-table-column>
        <b-table-column v-slot="props" field="name" label="Name" sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column v-slot="props" field="email" label="Email" sortable>
          {{ props.row.email }}
        </b-table-column>
        <b-table-column v-slot="props" field="phone" label="Phone" sortable>
          {{ props.row.phone }}
        </b-table-column>
        <b-table-column v-slot="props" field="nps" label="NPS" sortable>
          {{ props.row.nps }}
        </b-table-column>
        <b-table-column v-slot="props" field="url" label="URL">
          <a :href="props.row.url" target="_new">Link</a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="platform"
          label="Platform"
          sortable
        >
          {{ props.row.platform }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="connection"
          label="Connection"
          sortable
        >
          {{ props.row.connection }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="product"
          label="Browser Product"
          sortable
        >
          {{ props.row.product }}
        </b-table-column>
        <template slot="footer">
          <div v-show="feedback && feedback.length > 0" class="has-text-left">
            <span class="has-text-info has-text-weight-bold"
              >Total feedback submitted: {{ feedback.length }}</span
            >
            <br />
            <span class="has-text-info has-text-weight-bold"
              >Filtered feedback: {{ dateFilteredDataSet.length }}</span
            >
          </div>
        </template>
      </b-table>

      <div>
        <p v-if="error">
          {{ $t('Please try again or contact an administrator') }}:
          {{ error }}
        </p>
        <hr />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <npsGauge
          v-if="nps !== null"
          :nps-value="nps"
          :height="'200px'"
          width="450px"
        />
        <strong>
          <p>{{ $t('Average emotion feedback') }}: {{ averageAll }}</p>
        </strong>
        <strong>
          <p>
            {{ $t('Latest 7 day emotion average') }}:
            {{ averageLastSevenDays ? averageLastSevenDays : 'N/A' }}
          </p>
        </strong>
      </div>
      <div class="column">
        <feedbackPie
          v-show="pieData && pieData.length > 0"
          :pie-data="pieData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import { mapGetters } from 'vuex';

import NPSGauge from './NPSGauge';
import FeedbackPie from './FeedbackPie';

function compareRows(a, b) {
  if (a.feedbackDate < b.feedbackDate) {
    return 1;
  } else if (b.feedbackDate < a.feedbackDate) {
    return -1;
  } else {
    return 0;
  }
}

export default defineComponent({
  name: 'FeedbackViewer',
  searchOptions: {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: [
      'message',
      'appointmentNumber',
      'resourceId',
      'email',
      'rating',
      'nps',
      'name',
    ],
  },
  components: {
    npsGauge: NPSGauge,
    feedbackPie: FeedbackPie,
  },
  data() {
    return {
      autoRefresh: false,
      refreshInterval: undefined,
      refreshPeriod: 0,
      dateFrom: this.$moment().subtract(1, 'month').toDate(),
      dateTo: this.$moment().add(6, 'hours').toDate(),
      localTimezone: this.$moment.tz.guess(),
      nps: null,
      searchCriteria: null,
      searchResults: null,
      files: [],
      error: null,
      isFullPage: true,
      isLoading: false,
      file: null,
      dateFilteredDataSet: [],
      filteredByDate: false,
      keys: [
        'date',
        'appointmentNumber',
        'rating',
        'nps',
        'name',
        'phone',
        'email',
        'message',
        'resourceId',
        'parentResourceId',
        'url',
        'language',
        'token',
        'workZones',
        'product',
        'userAgent',
        'platform',
        'connection',
      ],
    };
  },
  computed: {
    ...mapGetters('storeFeedback', ['feedback']),
    demo() {
      return this.$configData.feedback.demo;
    },
    sortedArrayByDate() {
      if (this.feedback) {
        const feedbackToSort = [...this.feedback]; // eslint-disable-line
        const sortedFeedback = feedbackToSort.sort(compareRows); // eslint-disable-line
        return sortedFeedback;
      } else {
        return [];
      }
    },
    averageAll() {
      let totalFeedback = 0;
      if (this.feedback && this.feedback.length > 0) {
        for (let row of this.feedback) {
          const feedbackVal = row['rating'];
          totalFeedback += feedbackVal;
        }
        return (totalFeedback / this.feedback.length).toFixed(2);
      }
      return null;
    },
    averageLastSevenDays() {
      if (this.feedback && this.feedback.length > 0) {
        const filteredData = this.getLastXDays(7, this.sortedArrayByDate);
        if (filteredData.length === 0) {
          return '';
        }
        const sumFunc = (acc, cur) => acc + cur; // Calculates sum
        const total = filteredData.map((el) => el.rating).reduce(sumFunc, 0); // simplifies array to use rating only with map and then uses sumFunc to calculate sum
        const average = (total / filteredData.length).toFixed(2);
        return average;
      }
      return null;
    },
    pieData() {
      const pieDataArray = [];
      let hateCount = 0,
        dislikeCount = 0,
        neutralCount = 0,
        likeCount = 0,
        loveCount = 0; // eslint-disable-line
      for (let item of this.dateFilteredDataSet) {
        switch (item['rating']) {
          case 1: {
            hateCount++;
            break;
          }
          case 2: {
            dislikeCount++;
            break;
          }
          case 3: {
            neutralCount++;
            break;
          }
          case 4: {
            likeCount++;
            break;
          }
          case 5: {
            loveCount++;
            break;
          }
        }
      }
      pieDataArray.push(hateCount);
      pieDataArray.push(dislikeCount);
      pieDataArray.push(neutralCount);
      pieDataArray.push(likeCount);
      pieDataArray.push(loveCount);
      return pieDataArray;
    },
  },
  watch: {
    autoRefresh: {
      immediate: true,
      handler: async function (newValue) {
        if (newValue) {
          await this.fetchAndProcessData();
          this.refreshInterval = setInterval(async () => {
            await this.fetchAndProcessData();
            this.$forceUpdate();
          }, this.refreshPeriod);
        } else {
          if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
          }
        }
      },
    },
    dateFilteredDataSet: {
      immediate: true,
      handler: function () {
        this.calculateNps();
      },
    },
  },
  async created() {
    this.refreshPeriod = this.$configData.feedback.refreshInterval || 60000;
    await this.fetchAndProcessData();
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },
  methods: {
    resetDateFromAndTo() {
      this.dateFrom = this.$moment().subtract(1, 'month').toDate();
      this.dateTo = this.$moment().add(6, 'hours').toDate();
    },
    getLastXDays(daysToInclude, data) {
      const itemsToInclude = [];
      const includeItemsAfterDate = this.$moment().subtract(
        daysToInclude,
        'days',
      );
      for (let i = 0; i < data.length; i++) {
        const date = new Date(data[i]['feedbackDate']);
        const itemMoment = this.$moment(date);
        if (itemMoment.isAfter(includeItemsAfterDate, 'days')) {
          itemsToInclude.push(data[i]);
        } else {
          break;
        }
      }
      return itemsToInclude;
    },
    async fetchAndProcessData() {
      await this.fetchData();
      this.processData();
      this.calculateNps();
    },
    processData() {
      let stringFilteredData;
      if (this.searchCriteria && this.searchCriteria.length > 1) {
        stringFilteredData = this.searchResults;
      } else {
        stringFilteredData = this.sortedArrayByDate;
      }
      const dateFromMoment = this.$moment(this.dateFrom);
      const dateToMoment = this.$moment(this.dateTo);

      const dateFilteredResults = stringFilteredData.filter((feedbackEntry) => {
        const feedbackDate = this.$moment(new Date(feedbackEntry.feedbackDate));
        if (feedbackDate.isBetween(dateFromMoment, dateToMoment, 'day', '[]')) {
          return true;
        }
        return false;
      });
      this.dateFilteredDataSet = dateFilteredResults;
    },
    getMsgIcon(value) {
      if (value) {
        return 'check';
      } else {
        return 'minus';
      }
    },
    getMsgIconColor(value) {
      if (value) {
        return 'is-success';
      } else {
        return 'is-black';
      }
    },
    adjustToLocalTimezone(date) {
      const momentValue = this.$moment
        .tz(date, this.localTimezone)
        .format('DD-MM-YYYY HH:mm:ss');
      return momentValue;
    },
    calculateNps() {
      let detractorCount = 0;
      let promoterCount = 0;
      let totalNpsResponders = 0;

      this.dateFilteredDataSet.forEach((item) => {
        if (item.nps) {
          totalNpsResponders++;
        } else {
          return;
        }
        if (item.nps < 7) {
          detractorCount++;
        } else if (item.nps > 8) {
          promoterCount++;
        }
      });

      if (totalNpsResponders === 0) {
        this.nps = 0;
        return;
      }
      const detractorPercentage = (detractorCount / totalNpsResponders) * 100;
      const promoterPercentage = (promoterCount / totalNpsResponders) * 100;
      const result = promoterPercentage - detractorPercentage;
      this.nps = parseInt(result);
    },
    async fetchData() {
      const options = {
        method: 'GET',
        responseType: 'json',
        timeout: 300000,
        maxContentLength: 100000000,
        headers: {
          Authorization: `Bearer ${this.idToken}`,
          Accept: 'application/json',
        },
        url: this.$configData.feedback.retrievalUrl,
      };
      this.isLoading = true;
      try {
        const { data } = await axios(options);
        this.$store.dispatch('storeFeedback/storeFeedbackData', data);
        this.error = null;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    },

    async search() {
      this.searchResults = await this.$search(
        this.searchCriteria,
        this.feedback,
        this.$options.searchOptions,
      );
    },
    downloadCsv() {
      if (this.feedback && this.feedback.length > 0) {
        const dataForCsv = [];
        this.dateFilteredDataSet.forEach((row) => {
          const theDate = new Date(row['feedbackDate']);
          const formattedDate =
            this.$moment(theDate).format('DD-MM-YYYY HH:mm');
          let newRow = {};
          Object.assign(newRow, row);
          newRow['date'] = formattedDate;
          dataForCsv.push(newRow);
        });
        this.exportCsv('feedback_data', dataForCsv, this.keys);
      }
    },
    getIcon(value) {
      switch (value) {
        case 1:
          return 'angry';
        case 2:
          return 'frown';
        case 3:
          return 'meh';
        case 4:
          return 'smile';
        case 5:
          return 'smile-beam';
      }
    },
    getIconColor(value) {
      switch (value) {
        case 1:
          return 'is-danger';
        case 2:
          return 'is-warning';
        case 3:
          return 'is-light';
        case 4:
          return 'is-like';
        case 5:
          return 'is-success';
      }
    },
    rowClass(row) {
      if (row['rating'] === 1) {
        return 'is-hate';
      }
      if (row['rating'] === 2) {
        return 'is-dislike';
      }
      if (row['rating'] === 3) {
        return 'is-neutral';
      }
      if (row['rating'] === 4) {
        return 'is-like';
      }
      if (row['rating'] === 5) {
        return 'is-love';
      }
    },
  },
});
</script>

<style scoped lang="scss">
$love: #34b73b;
$like: #2d94f2;
$like-invert: #d26b0d;
$dislike: #f39c12;
$hate: #70221a;

$neutral: #999999;

.feedback-table {
  font-size: 20px;
  width: 100%;
}

.myButton {
  margin-bottom: 10px;
}

tr.is-hate {
  /* background: hsl(9, 99%, 67%) !important; */
  color: $hate !important;
}

tr.is-dislike {
  /* background: hsl(34, 99%, 67%) !important; */
  background: $dislike !important;
  color: white;
}

tr.is-neutral {
  /* background: hsla(33, 7%, 75%, 0.842) !important; */
  background: $neutral !important;
  color: white;
}

tr.is-like {
  /* background: hsla(219, 71%, 45%, 0.842) !important; */
  background: $like !important;
  color: white;
}

tr.is-love {
  /* background: hsla(138, 76%, 46%, 0.842) !important; */
  background: $love !important;
  color: white;
}

.chart-position {
  padding-top: 2rem !important;
}
</style>
