<template>
  <div class="has-text-centered">
    <p class="has-text-centered is-size-3">{{ $t('Customer Feedback NPS') }}</p>
    <v-gauge
      :options="options"
      :value="npsValue"
      :min-value="-100"
      :max-value="100"
      :height="height"
      :width="'400px'"
    ></v-gauge>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VGauge from 'vgauge';

export default defineComponent({
  name: 'NPSGauge',
  components: {
    VGauge,
  },
  props: {
    npsValue: {
      type: Number,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '300px',
    },
  },
  data() {
    return {
      options: {
        lines: 12,
        angle: 0.1,
        lineWidth: 0.44,
        pointer: {
          length: 0.9,
          strokeWidth: 0.035,
          color: '#000000',
        },
        limitMax: 'false',
        percentColors: [
          [0.0, '#ff2400'],
          [0.5, '#008ffb'],
          [1.0, '#2ad61e'],
        ],
        strokeColor: '#E0E0E0',
        generateGradient: true,
        renderTicks: {
          divisions: 5,
          divWidth: 1.1,
          divLength: 0.4,
          divColor: '#333333',
          subDivisions: 3,
          subLength: 0.2,
          subWidth: 0.6,
          subColor: '#666666',
        },
        staticLabels: {
          font: '12px sans-serif', // Specifies font
          labels: [-100, -50, 0, 50, 100], // Print labels at these values
          color: '#000000', // Optional: Label text color
          fractionDigits: 0, // Optional: Numerical precision. 0=round off.
        },
      },
    };
  },
});
</script>
