<template>
  <div>
    <p class="has-text-centered is-size-3">{{ $t('Customer Star Rating') }}</p>
    <div ref="chart"></div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FeedbackPie',
  props: {
    pieData: {
      type: Array,
      required: true,
      default: () => [],
    },
    colors: {
      type: Array,
      required: false,
      default: () => ['#ff2400', '#f39c12', '#999999', '#2d94f2', '#34b73b'],
    },
    labels: {
      type: Array,
      required: false,
      default: () => ['Hate', 'Dislike', 'Neutral', 'Like', 'Love'],
    },
    width: {
      type: Number,
      required: false,
      default: 500,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'donut',
          width: this.width,
        },
        labels: this.labels,
        series: this.pieData,
        legend: {
          show: true,
        },
        colors: this.colors,
      },
      chart: undefined,
    };
  },
  watch: {
    pieData: {
      handler: function (newValue) {
        this.chart.updateSeries(newValue);
      },
    },
  },
  mounted() {
    const chartEl = this.$refs.chart;
    this.chart = new ApexCharts(chartEl, this.options);
    this.chart.render();
  },
});
</script>
